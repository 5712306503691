import "../styles/globals.css";
import "../styles/adyen.scss";

import type { AppProps } from "next/app";
import Script from "next/script";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      {/*GTM*/}
      {process.env.NEXT_PUBLIC_GTM_ID &&
        process.env.NEXT_PUBLIC_GTM_AUTH_ID &&
        process.env.NEXT_PUBLIC_GTM_ENV && (
          <Script defer={true}>
            {`
            function decodePreOrderId(encodedId) {
              let decodedId = encodedId;
              try {
                while (decodedId.startsWith('"') && decodedId.endsWith('"')) {
                  decodedId = JSON.parse(decodedId);
                }
              } catch (e) {}
              return decodedId;
            }
            function generateUUID(){
              let d = new Date().getTime();
              let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
              return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                let r = Math.random() * 16;
                if (d > 0) {
                  r = (d + r) % 16 | 0;
                  d = Math.floor(d / 16);
                } else {
                  r = (d2 + r) % 16 | 0;
                  d2 = Math.floor(d2 / 16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
              });
            }
            function getSessionId(){
              if(window.crypto && window.crypto.randomUUID){
                return window.crypto.randomUUID();
              }
              else {
                return generateUUID();
              }
            }
            async function logInit(props){
              try{
                let sessionId = sessionStorage.getItem("sessionId");
                let preOrderId = sessionStorage.getItem("ss.preOrderId");
                if(preOrderId){
                  preOrderId = decodePreOrderId(preOrderId);
                }
                if(!sessionId){
                  sessionId = getSessionId();
                  sessionStorage.setItem("sessionId", sessionId);
                }
                await fetch("${process.env.NEXT_PUBLIC_API_BASE}/info/track", {
                  method: "POST",
                  body: JSON.stringify({
                    event: "init",
                    frontendVersion: String(props.frontendVersion),
                    sessionId: sessionId,
                    version : "${process.env.NEXT_PUBLIC_APP_VERSION}",
                    preOrderId: preOrderId
                  }),
                  headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                  },
                })
              }catch(err){}
            }
            if(!window._learnq){
              window._learnq = [];
            }
            window.dataLayer = window.dataLayer || [];
            function logABAssignment(){
              try{
                  const abTestingCookiePrefix = "ab_test_";
                  if(document.cookie){
                    const abExperiments = document.cookie
                      .split(";")
                      .map((keyValueString) => keyValueString.trim())
                      .filter((keyValueString) =>
                        keyValueString.startsWith(abTestingCookiePrefix)
                      )
                      .map((keyValueString) => {
                        try {
                          const [key, value] = keyValueString.split("=");
                          return [key, JSON.parse(decodeURIComponent(value))];
                        } catch (err) {
                          return null;
                        }
                      })
                      .filter(Boolean).forEach(([key, {variant, version}]) => {
                        if(window.dataLayer && key && variant && version){
                          window.dataLayer.push({
                            event: "ab_exp_assignment",
                            exp_name: key,
                            exp_variant: variant,
                            exp_version: version
                          });
                        }
                    });
                  }
              }catch(err){}
            }
            const menuUserType = localStorage.getItem("menuUserType");
            let version = 0;
            if(menuUserType === "NEW"){
                version = 2;
            } else if(menuUserType === "LEGACY"){
                version = 1;
            }
            const queryObject = Object.fromEntries(window.location.search.replace("?", "").split("&").map(keyValueString => keyValueString.split("=")))
              window.dataLayer.push({
              event: "init",
              frontend_version: version,
              ...(!!queryObject.redirect_referrer ? {redirect_referrer: decodeURIComponent(queryObject.redirect_referrer)}: {}),
            });
            logABAssignment();
            logInit({frontendVersion: version}).catch(() => {});
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${process.env.NEXT_PUBLIC_GTM_AUTH_ID}&gtm_preview=${process.env.NEXT_PUBLIC_GTM_ENV}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
        `}
          </Script>
        )}
      <Script>
        {`window.zESettings = {
              "webWidget": {
                  "color": {
                      "theme": "#ff506e"
                  },
                  "offset": {
                      "horizontal": "0px",
                      "vertical": "40px"
                  },
                  "mobile": {
                      "labelVisible": true
                  }
              }
          }`}
      </Script>
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
